.landing-page {
    padding: 40px 15px;
    background-image: radial-gradient(circle at 80% 45%, rgba(255, 128, 181, 0.3), rgba(144, 137, 252, 0.3), rgba(255,255,255,0) 35%),
        radial-gradient(farthest-side at 80% 50%, rgba(144, 137, 252, 0.3), rgba(255, 128, 181, 0.3), rgba(255,255,255,0)50%),
        radial-gradient(farthest-side at 70% 45%, rgba(144, 137, 252, 0.3), rgba(255, 128, 181, 0.3), rgba(255,255,255,0)50%),
        radial-gradient(farthest-corner at 90% 10%, rgba(144, 137, 252, 0.3), rgba(255, 128, 181, 0.3), rgba(255,255,255,0) 20%),
        radial-gradient(farthest-corner at 55% 25%, rgba(144, 137, 252, 0.3), rgba(255, 128, 181, 0.2), rgba(255,255,255,0) 25%);
    height: calc(100vh - calc(3.5rem * 1.5));
}

.hero-section {
    padding-left: 2%;
    padding-right: 2%;
    height: 720px;
}

.headline-section {
    width: 50%;
    text-align: right;
}

.release {
    border-radius: 25px;
    width: fit-content;
    padding: 7px 15px;
    box-shadow: 0 0px 100px 10px var(--light-grey);
    background-color: var(--white);
    margin-top: 18%;
    margin: 15% auto 5px;
}

.landing-page .headline {
    text-align: left;
    width: 80%;
    margin: auto;
    font-size: 15px;
  }
  
.landing-page .headline-text {
    font-size: 2.9rem;
    font-weight: 400;
    margin-top: 25px;
    margin-bottom: 70px;
    text-align: left;
}

.headline-text .highlight {
    font-weight: 700;
}
  
.landing-page .landing-subtitle {
    font-size: 24px;
    width: 33%;
    margin: 0 auto 50px;
}

.landing-page .call-to-action-buttons-div {
    padding-left: 5%;
    padding-right: 5%;
    margin-top: 40px;
}
  
.landing-page .btn-primary {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color: var(--white);
    font-size: 16px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px 8px;
    width: 135px;
}

.landing-page .btn-explore {
    /* background-color:  */
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    padding: 10px 8px;
    font-size: 16px;
    margin-left: 10px;
    margin-right: 10px;
    width: 135px;
}

.landing-page .image-preview-section {
    /* display: inline-block; */
    width: 65%;
    margin: 70px 0;
}

.landing-page .image-preview-section img {
    width: 100%;
    border-radius: 8px;
}

.features-section {
    text-align: center;
    /* height: 800px; */
}

.feature {
    margin: 5% auto;
    padding: 10px;
}

.feature-heading {
    font-size: 2.1rem;
}

.feature-description {
    font-size: 1.15rem;
    margin: 2% auto 1.2%;
    display: block;
    max-width: 60%;
    text-align: justify;
}

.feature-preview-holder img {
    max-width: 60%;
    border: 4px solid rgba(200, 200, 200, 0.1);
    box-shadow: 0 0 20px 1px var(--light-grey);
    border-radius: 10px;
}

.footer .divider-icon-pair {
    display: contents;
    text-align: center;
}

.footer .divider {
    background-color: var(--light-grey);
    flex-grow: 1;
    height: 1px;
    max-width: 20%;
    margin-top: auto;
    margin-bottom: auto;
}

.footer .icons {
    font-size: 1.5rem;
    padding-left: 2%;
    padding-right: 2%;
}

.footer .icons .icon {
    margin-left: 10px;
    margin-right: 10px;
}

.footer .tnc-section {
    text-align: center;
    margin: 30px auto 15px;
}

.ml-auto {
    margin-left: auto;
}

.mr-auto {
    margin-right: auto;
}

.footer-link {
    font-size: 0.7rem;
    margin-top: auto;
    margin-bottom: auto;
}

@media (max-width: 768px) {
    .landing-page {
        padding: 20px 10px; /* Reduce padding */
    }

    .hero-section {
        max-height: 70vh;
    }

    .release {
        font-size: 14px;
        margin-top: 20%;
    }
  
    .headline-section {
        width: 100%;
        text-align: center;
    }

    .headline {
        float: none !important;
        text-align: center !important;
        margin: auto !important;
    }

    .headline-text {
        font-size: 2rem !important;
    }

    .image-preview-section {
        display: none;
    }

    .call-to-action-buttons-div button {
        width: 100%; /* Full width buttons */
        margin: 10px 0; /* Margin adjustment */
    }

    .feature-heading {
        font-size: 2rem;
    }

    .feature-description {
        max-width: 90%;
        font-size: 1rem;
        margin: 2% auto 5%;
    }

    .feature-preview-holder img {
        max-width: 75%;
    }

    .footer .tnc-section {
        margin: 15px auto 30px;
    }
}
