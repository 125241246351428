.privacy-policy-container {
    margin-top: 5%;
    margin-bottom: 10%;
    text-align: center;
    max-width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.privacy-policy-heading {
    font-size: 2.5rem;
}

.privacy-policy-content {
    text-align: justify;
    margin-top: 3%;
    font-size: 1.125rem;
    line-height: 1.8;
    color: #808080;
}

.privacy-policy-content p {
    margin-top: 2%;
}

.privacy-policy-content b {
    color: #333333;
}