/* PaperQnA.css */
.paper-layout {
    display: flex;
    max-height: 100vh;
    padding-left: 1rem;
    padding-right: 1rem;
}

.back-to-dash {
    padding: 10px 20px;
}

.back-button {
    font-size: 1.5rem;
}

.back-to-dash .text {
    font-size: 1rem;
    margin-left: 10px;
}

.pdf-view {
    flex-grow: 1;
}

.chatroom {
    flex: 0 0 52%;
    min-width: 0;
    max-height: calc(100vh - 51px);
    /* flex: 0 0 45%; 35% of the container */
    /* Additional styling for chat section */
    /* height: 100vh; */
}

@media (max-width: 992px) {
    .pdf-view {
        display: none;
    }
    .chatroom {
        flex-grow: 1;
    }
}