.mobile-warning-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-title {
    font-weight: bold;
}

.modal-content {
    background-color: var(--white);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 90%;
}

.modal-body {
    margin-top: 15px;
    margin-bottom: 25px;
    text-align: justify;
}

.close-modal-btn {
    background-color: var(--primary-color);
    color: var(--white) !important;
    font-size: 1.3rem !important;
    padding: 5px 15px !important;
}