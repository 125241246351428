.loading-screen {
    padding-top: 10%;
}

.spinner-dimension {
    height: 1.5rem;
    width: 1.5rem;
}

.loading-text-headline {
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 25px;
    margin-bottom: 10px;
}

.dashboard-main-container {
    width: 100%;
    padding: 5rem;
    position: relative;
}

.left-section {
    max-width: 70%;
}

.greeter {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 2.1rem;
    margin-left: 2rem;
  }