.search-results-overlay {
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--primary-background);
  z-index: 2;
  overflow-y: auto;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  opacity: 0;
  visibility: hidden;
}

.search-results-overlay.active {
  opacity: 1;
  visibility: visible;
}

.search-results-container {
  padding: 8rem 4rem;
}
.close-btn {
  align-self: flex-end;
  margin-bottom: 20px;
  cursor: pointer;
  float: right;
  height: 2rem;
  width: 2rem;
}

.search-headline {
  font-size: 18px;
  margin-left: 2rem;
}

.underline {
  height: 1px;
  width: 85%;
  background-color: var(--light-grey);
  margin-top: 15px;
  margin-bottom: 25px;
}

.result-display-area {
  padding: 0 2rem;
  width: 75%;
}
  
.result-item {
  padding: 20px;
  cursor: pointer;
}

.result-item:hover {
  background-color: var(--white);
}

.result-item.disabled {
  opacity: 0.4; /* Makes the entry appear faded */
  pointer-events: none; /* Prevents clicking on the item */
  cursor: not-allowed; /* Shows a not-allowed cursor on hover */
}

.result-title {
  font-weight: 700;
  font-size: 20px;
}

.result-author {
  font-size: 14px;
  color: var(--light-grey-text);
}

.result-topics {
  padding-top: 10px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.pdf-not-found {
  color: red;
  margin-left: 10px;
  font-size: 0.8rem;
}


.topic-tag {
  background-color: var(--search-topic-background);
  border-radius: 15px; /* Rounded corners for bubble effect */
  padding: 5px 10px; /* Padding inside the bubble */
  margin-right: 5px; /* Space between tags */
  margin-bottom: 5px; /* Space below each tag */
  font-size: 0.8rem; /* Smaller font size for tags */
  color: var(--search-topic-text);
}

.pagination {
  margin-top: 2rem;
  margin-bottom: 0;
}

.page-link {
  color: var(--primary-color);
}

.page-link:focus {
  box-shadow: none;
  background-color: var(--white);
}

.pagination .active > .page-link{
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}