.bg-grey {
    background-color: var(--primary-background);
}

.raised {
    position: fixed;
    width: 65%;
    top: 0;
    margin-top: 2rem;
    z-index: 3;
}

.search-bar-container {
    display: flex;
    font-size: 17px;
}

.search-field-selector {
    border-radius: 8px 0 0 8px;
    background-color: var(--white);
    outline: none;
    border: none;
    padding: 0 1rem;
}

.search-bar {
    width: 75%;
    height: 50px;
    padding: 0px 15px;
    border: 0;
    box-sizing: border-box;
    background-color: var(--white);
    outline: none;
}

.search-icon {
    background-color: var(--white);
    padding: 0px 15px;
    color: var(--primary-font-color);
    cursor: pointer;
    border-radius: 0 8px 8px 0;
}

.spinner {
    width: 1.2rem;
    height: 1.2rem;
}

/* .search-bar-container.raised {
    position: fixed;
    width: 65%;
    top: 0;
    margin-top: 2rem;
    z-index: 3;
} */
  
.quick-search-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    font-size: 0.8rem;
  }
  
.quick-search-bubble {
    background-color: #f0f0f0;
    border-radius: 20px;
    padding: 5px 10px;
    min-width: 90px;
    margin: 5px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
  
.quick-search-bubble:hover {
    background-color: #e0e0e0;
}





.toggle-switch {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    margin-top: 0.25rem;
    float: right;
    padding-right: 25%;
}
  
.label {
    font-size: 0.9rem;
    cursor: pointer;
    color: #888;
    padding-left: 10px;
    padding-right: 10px;
}
  
.label.active {
    color: #000;
}
  
.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 8px;
}
  
.toggle-input {
    opacity: 0;
    width: 0;
    height: 0;
}
  
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 24px;
}
  
.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: -5px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
    border: 2px solid var(--primary-color);
}
  
.toggle-input:checked + .slider {
    background-color: var(--primary-color);
}
  
.toggle-input:checked + .slider:before {
    transform: translateX(26px);
}
  