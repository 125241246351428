.chat-container {
    margin-left: 1rem;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    height: 100%;
}
  
.messages-display {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-height: calc(100vh - 125px);
    overflow-y: auto;
    /* justify-content: flex-end; */
}

.messages-display > :first-child {
  margin-top: auto;
}

.message-box {
  padding: 2px 20px;
}
.message {
    margin: 5px;
    padding: 15px 20px;
    border-radius: 10px;
    word-break: break-word;
}

.message-input-container {
    border-top: 1px solid var(--dark-grey);
    background-color: var(--white);
    padding: 10px 20px;
    /* position: sticky;
    bottom: 0; */
}

.message-input {
    /* padding: 15px 20px !important; */
    /* height: auto !important; */
    min-height: 38px;
    resize: none;
    overflow-y: hidden;
    font-size: 1.1rem;
}

.message-input:focus {
    outline: none;
    box-shadow: none;
}
  
.btn {
    font-size: 1.2rem;
    border: none;
    color: var(--dark-grey);
    padding-top: 0;
    padding-bottom: 0;
    margin: auto;
}

.message-box-user-message {
    align-self: flex-end;
}

.summary-message {
    color: var(--primary-font-color);
}

.user-message {
    background-color: #867ed2;
    color: var(--white)
    /* background-color: #DCF8C6; */
}

.message-box-assistant-message {
    align-self: flex-start;
}

.assistant-message {
    align-self: flex-start;
    /* background-color: #EFEDFA; */
    background-color: #F0F0F0;
    color: var(--primary-font-color);
}

.spinner-dimension {
    height: 1.5rem;
    width: 1.5rem;
}

.loading-text {
    font-size: 1.25rem;
    /* padding-top: 15px; */
    padding-bottom: 18px;
}

.desc-text {
    font-size: 0.85rem;
    margin-top: 10px;
    color: var(--light-grey-text);
}

.error-icon {
    font-size: 3rem;
    color: var(--primary-red);
}

.error-text {
    font-size: 1.15rem;
    padding-top: 15px;
}

table img {
    max-width: 75%;
    max-height: 375px;
}

table:has(img) {
    width: 100%;
}

table:has(img) th,
table:has(img) td {
    border: none;
}
/* .typing-indicator {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: black;
    margin: 0 2px;
    animation: typing 1s infinite;
  }
  
  @keyframes typing {
    0% { opacity: 0.1; }
    50% { opacity: 1; }
    100% { opacity: 0.1; }
  } */

.typing-indicator {
    display: inline-block;
}
  
.typing-indicator div {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: black;
    margin: 0 2px;
    animation: typing 1s infinite;
}
  
.typing-indicator div:nth-child(1) {
    animation-delay: 0s;
}
  
.typing-indicator div:nth-child(2) {
    animation-delay: 0.33s;
}
  
.typing-indicator div:nth-child(3) {
    animation-delay: 0.66s;
}
  
@keyframes typing {
    0% { opacity: 0.1; }
    50% { opacity: 1; }
    100% { opacity: 0.1; }
}

.socketio-reconnect-indicator {
    height: 1.5rem;
    width: auto;
}

.feedback-spacer {
    align-self: flex-end;
    margin-right: 20px;
    margin-bottom: 25px;
}

.feedback-icons {
    display: flex;
    justify-content: center;
    margin-top: 5px;
    font-size: 1.2rem;
}
  
.feedback-icon {
    cursor: pointer;
    margin: 0 5px;
}
  
.feedback-icon.selected {
    color: #6047ca;
}
  
.sample-questions-container {
    overflow-x: auto;
    padding: 10px;
}
  
.sample-questions {
    display: flex;
    gap: 10px;
}

.sample-question {
    align-items: center;
    background-color: #f8f9fa;
    border: 1px solid #C0C0C0;
    border-radius: 10px;
    color: #495057;
    display: flex;
    flex: 0 0 auto;
    font-size: 0.85rem;
    font-weight: 600;
    justify-content: center;
    line-height: 1.3;
    max-width: 200px;
    padding: 10px 10px;
    text-align: center;
    white-space: normal;
    width: auto;
    word-wrap: break-word;
  }

.progress-container {
    height: 8px;
}

.progress {
    width: 300px;
    height: 100%;
}

.progress-bar {
    background-color: var(--primary-color);
}

h3 {
    font-weight: 900;
    font-size: 1.2rem;
}

h4 {
    font-weight: 600;
    font-size: 1.1rem;
}

h5 {
    font-weight: 300;
}

p {
    font-size: 0.85rem;
}

table {
    margin-bottom: 15px;
}

th {
    min-width: 75px;
}

th, td {
    border: 1.2px solid var(--primary-font-color);
}